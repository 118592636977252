<template>
  <div>
      <div class="card card-custom" v-loading="loading">
          <div class="card-body p-0">
              <el-form>
                <h3 class="mb-10 font-weight-bold text-dark"><i class="flaticon-calendar-with-a-clock-time-tools"></i> {{tittle}}</h3>
                <div class="row mt-5">                    
                  <div class="col-sm-2">
                    <el-form-item label="Employee Name" prop="order_type">
                      <el-select
                          clearable
                          filterable
                          v-model="form.employee_id"
                          placeholder="Select Employee"
                          style="width: 100%"
                          :allow-create="true"  
                           @change="getEmployee()"                      
                        >
                          <el-option
                            v-for="employee in employee_list"
                            :key="employee.id"
                            :label=" employee.first_name +
                              ' ' +
                              (employee.last_name == null
                                ? ''
                                : employee.last_name)
                            "
                            :value="employee.id"
                          ></el-option>
                        </el-select>
                    </el-form-item>
                  </div>
                  <div class=" col-sm-4">
                      <el-form-item label="Date Range" prop="date_range">                  
                        <el-date-picker
                          v-model="date_range"
                          type="daterange"
                          align="right"
                          unlink-panels
                          range-separator="-"
                          start-placeholder="Start date"
                          end-placeholder="End date"
                          value-format="yyyy-MM-dd"
                          >
                        </el-date-picker>
                      </el-form-item>
                  </div>
                  <div class=" col-sm-4">
                    <el-button type="primary" class="search_button" @click="getEmployee()"><i class="el-icon-search" style="color:#fff"></i> Search</el-button>
                    <el-button type="success" class="search_button" @click="downloadTimeSheet()"><i class="fas fa-file-pdf" style="color:#fff"></i> Download</el-button>
                    <el-button type="success" class="search_button" @click="downloadExcelTimeSheet()"><i class="fas fa-file-excel" style="color:#fff"></i> Excel</el-button>
                   
                  </div> 
                                 
               </div>
              </el-form>
          <div class="row mt-3">
              <div class="col-sm-12">
                  <el-table
                  :data="list"
                  border
                  element-loading-text="Loading"
                  style="width: 100%"
                  v-loading="loading"
                  >
                   
                  <el-table-column
                      fixed
                      align="center"
                      label="Date"
                      prop="id"                        
                      width="120"
                  >
                      <template slot-scope="scope">
                        {{getDate(scope.row.created_at)}}                      
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed
                      align="center"
                      label="Day"
                      prop="id"                        
                      width="90"
                  >
                      <template slot-scope="scope">
                    {{getDay(scope.row.created_at)}} 
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed                       
                      label="Actual In"
                      prop="id" 
                      width="100"
                  >
                      <template slot-scope="scope">
                          <span>{{scope.row.actual_time_in}}</span> <span>{{scope.row.in_meridiem}}</span>
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed                       
                      label="Actual  Out"
                      prop="id" 
                      width="100"
                  >
                      <template slot-scope="scope">
                          <span>{{scope.row.actual_time_out}}</span> <span>{{scope.row.out_meridiem}}</span>
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed                       
                      label="Record In"
                      prop="id" 
                      width="100"
                  >
                      <template slot-scope="scope">
                          <span>{{scope.row.record_time_in}}</span> <span>{{scope.row.in_meridiem}}</span>
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed                       
                      label="Record  Out"
                      prop="id" 
                      width="100"
                  >
                      <template slot-scope="scope">
                          <span>{{scope.row.record_time_out}}</span> <span>{{scope.row.out_meridiem}}</span>
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed
                      align="center"
                      label="Status"
                      prop="id"
                      :min-width="150"
                      
                  >
                      <template slot-scope="scope">
                        <span v-if="scope.row.status =='Check IN'">
                            <span v-if="scope.row.in_meridiem == 'AM'">     
                              <span  v-if="scope.row.actual_time_in >= 8.00" class="red">{{scope.row.status}}</span>
                              <span  v-else class="active">{{scope.row.status}}</span>
                            </span>
                            <span v-if="scope.row.in_meridiem == 'PM'">     
                                <span  class="red"><b>{{scope.row.status}}</b></span>                      
                            </span>
                        </span>
                        <span v-else-if="scope.row.status =='Check Out'">
                           <span  class="active"><b>{{scope.row.status}}</b></span> 
                        </span>
                        <span v-else>
                          <span  class="red"><b>{{scope.row.status}}</b></span> 
                        </span>
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed                       
                      label="Lunch Break"
                      prop="id" 
                      width="100"
                  >
                      <template slot-scope="scope">
                        <span v-if="scope.row.total_hours !='0'">{{scope.row.lunch_break}} Min</span>
                        <span v-else>                            
                           <span v-if="scope.row.status =='Check IN'">
                             <span >{{checkLunchBreak(current_time,scope.row.record_time_in,scope.row.in_meridiem)}}</span>  
                            </span>
                        </span>
                      </template>
                  </el-table-column>
                   <el-table-column
                      fixed
                      align="center"
                      label="Total Hours"
                      prop="id"
                      width="100"
                  >
                      <template slot-scope="scope">
                        <div v-if="scope.row.total_hours !='0'">{{scope.row.total_hours}}</div>
                        <div v-else>
                            <span v-if="scope.row.status =='Check IN'">
                            <span v-if="scope.row.in_meridiem == 'AM'">                                
                              <span >{{timeCalculation(current_time ,scope.row.actual_time_in)}}</span>                               
                            </span>
                           <span v-if="scope.row.in_meridiem == 'PM'">
                              <span >{{totalCalculationPM(current_time ,scope.row.actual_time_in)}}</span>             
                           </span>
                            </span>                               
                        </div>
                      </template>
                 
                  </el-table-column>
                  </el-table>
              </div>
          </div>
           <div class="row">
              <div class="col-sm-12">
                <el-pagination
                  v-if="!loading"
                  style="margin-top: 15px"
                  background
                  layout="total, sizes, prev, pager, next, jumper"
                  @size-change="handleSizeChange"
                  @current-change="fetchData"
                  :page-count="pagination.last_page"
                  :page-sizes="[50, 100, 250, 500, 1000]"
                  :page-size="pagination.per_page"
                  :current-page.sync="pagination.current_page"
                  :total="pagination.total_rows"
                >
                </el-pagination>
              </div>
            </div>
          </div>
      </div>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import {getEmployeeDetails,getEmployeeList,downloadPdf,downloadEmployeeExcel} from "@/api/employeeLogin";
import Form from '@/core/services/api.form.services'
import moment from 'moment-timezone';
const candaTime = moment.tz.setDefault('America/Toronto');
const date = new Date(new Date().toLocaleString('en', {timeZone: 'America/Toronto'}));
export default {
  name: 'employee_time_sheet',
  props:{
   employeeId: {
    type: Number,
  },
  date_ranges:{
    type: Array
  }
  },
   data() {
  return {
      loading: false,
      tittle:'Employee Time Sheet',
      multipleSelection: [],
      pagination: {
      total_rows: 1,
      current_page: 1,
      last_page: null,
      per_page: 50,
    },
    list : [],
     dateTime: {
      hours: date.getHours(),
      minutes: date.getMinutes(),
      seconds: date.getSeconds(),
     
    },
     form : new Form({  
       start_date:null,
       end_date:null,
       employee_id : null,
     }),
    current_time : date.getHours() +':'+('0'+date.getMinutes()).slice(-2),
    current_meridiem : '',
    timer:undefined,
    employee_list:[],
    date_range : [],
    excell_data:[],
  }
 },
 mounted() {
  this.$store.dispatch(SET_BREADCRUMB, [
    { title: "Dashboard", route: "/dashboard" },
    { title: "Employees", route: "/employee/list" },
    { title: "Employee Time Sheet" }
  ]);
},
 created() {
   this.getEmployeeList();
   this.form.employee_id = this.employeeId    
   const candaTime = moment.tz.setDefault('America/Toronto'); 
   const startOfMonth = candaTime().startOf('month');
   const current_date = candaTime(date).format('YYYY-MM-DD');
   let now = moment();
   let month = moment().month();
   let firstMonday = moment().year(now.toDate().getFullYear()).month(month).date(1).day(8);
	if (firstMonday.date() > 7) {
	    firstMonday.day(-6);
	}
   var start_date = candaTime(firstMonday).format('YYYY-MM-DD');
   var end_date = candaTime(start_date).add(13, 'days').format('YYYY-MM-DD');
   var start_date1 = candaTime(start_date).add(14, 'days').format('YYYY-MM-DD');
   var start_date_next = candaTime(startOfMonth).add(4, 'days').format('YYYY-MM-DD');
   var end_date1 = candaTime(start_date_next).add(1, 'months').format('YYYY-MM-DD');
   this.date_range = [start_date,end_date]
   if(current_date >= start_date1){
     this.date_range = [start_date1,end_date1]       
   }
  var start_date2 = candaTime(start_date1).add(-1, 'months').format('YYYY-MM-DD');
  if(current_date <start_date){
     this.date_range = [start_date2,start_date_next]  
  }
   if(this.date_ranges){
     this.date_range = this.date_ranges
   }  
    this.fetchData(1);
//   const candaTime = moment.tz.setDefault('America/Toronto'); 

//   var afterTwoWeeks = new Date(+new Date + 12096e5);
//  alert(candaTime(afterTwoWeeks));
 
 },
methods: {
  getEmployeeDate(){
    this.fetchData(1);
  },
   getDate(date){
    return candaTime(date).format('DD-MM-YYYY')
  },
  getDay(date){
    return candaTime(date).format('dddd')
  },
  handleSizeChange(per_page) {
    this.pagination.per_page = per_page;
    this.fetchData(1);
  },
   timeConvert(n) {
    var num = n;
    var hours = (num / 60);
    var rhours = Math.floor(hours);
    var minutes = (hours - rhours) * 60;
    var rminutes = Math.round(minutes);
    return  rhours + ' : ' + rminutes ;
    },
  timeCalculation(time1, time2){ 
      var Time1 = time1
      var Time2 = time2
      var splitTime1= Time1.split(':');
      var splitTime2= Time2.split(':');
      var hour=0;
      var ct_hour = (parseInt(splitTime1[0]))*60+(parseInt(splitTime1[1]))        
      var record_in = (parseInt(splitTime2[0]))*60+(parseInt(splitTime2[1]))
      
      var h = 0;
      if(ct_hour > record_in){
         h = ct_hour - record_in
      }else{
        h = 0
      }
    
      if(h>=60){  
        hour = this.timeConvert(h);
      }else{
        hour = '0 : '+h
      }  
      
      
        if(h > 270){
          var total = h - 30
           hour = this.timeConvert(total);
        }
          return hour;           
    },   
    totalCalculationPM(time1, time2){
     
      var splitTime1= time1.split(':');
      var splitTime2= time2.split(':');
      var hour=0;
   
      var ct_hour = (parseInt(splitTime1[0])-12)*60+(parseInt(splitTime1[1])) 
      if(splitTime1[0]== 12){
        ct_hour = (parseInt(splitTime1[0]))*60+(parseInt(splitTime1[1])) 
      }    
      var record_in = (parseInt(splitTime2[0]))*60+(parseInt(splitTime2[1]))
      
      var h = 0;
      if(ct_hour > record_in){
         h = ct_hour - record_in
      }else{
        h = 0
      }
    
      if(h>=60){  
        hour = this.timeConvert(h);
      }else{
        hour = '0 : '+h
      }

       if(h > 270){
          var total = h - 30
           hour = this.timeConvert(total);
        }            
          return hour;       
    }, 
    checkLunchBreak(current, checkIn,in_meridiem){
      var total = 0;
     if(in_meridiem == "AM"){
        total = this.timeCalculation(current,checkIn);
     }else{
        total = this.totalCalculationPM(current,checkIn);
     }
       
       var breakTime = total.split(':');
       var totalMin =  (parseInt(breakTime[0])*60)+parseInt(breakTime[1]);
      
        if(totalMin > 270){
          return '30 Min'
        }else{
          return '0 Min'
        }

    },  
    handleSelectionChange(val) {
      this.multipleSelection = val;
    },
   
    getEmployee(){
      this.fetchData(1);
    },
  fetchData(p) {
    this.loading = true;
   
    let params = { page: p };

    if (this.pagination.per_page) {
      params.per_page = this.pagination.per_page;
    }
    params.employee_id = this.form.employee_id;

    if(this.date_range){
      for(var i=0;i<this.date_range.length;i++){
         params.start_date = this.date_range[0];
         params.end_date = this.date_range[1];
      }
    }
   
    getEmployeeDetails(params).then((response) => {        
      this.list = response.data.data.data;        
      this.getExcelData(this.list);
      this.pagination.current_page = response.data.data.current_page;
      this.pagination.last_page = response.data.data.last_page;
      this.pagination.total_rows = response.data.data.total;
      this.loading = false;
      this.$set(this.list, "_showDetails", true);
    });
    
  },
  getEmployeeList(){
    this.loading = true;
    getEmployeeList().then((response) => {       
        this.loading = false;
        this.employee_list = response.data.data;
      });
  },
   downloadTimeSheet(){
        let params = { page: 1 };
         params.employee_id = this.form.employee_id;

    if(this.date_range){
      for(var i=0;i<this.date_range.length;i++){
         params.start_date = this.date_range[0];
         params.end_date = this.date_range[1];
      }
    }
            this.loading=true;
            downloadPdf(params).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'employeeTimeSheet.pdf');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
        
     
  },

  downloadExcelTimeSheet(){     
      
      let params = { page: 1 };
          params.employee_id = this.form.employee_id;

            if(this.date_range){
              for(var i=0;i<this.date_range.length;i++){
                params.start_date = this.date_range[0];
                params.end_date = this.date_range[1];
              }
            }
            this.loading=true;
            downloadEmployeeExcel(params).then(response => {
              //console.log(response.data);
              const url = window.URL.createObjectURL(new Blob([response.data]));
              const link = document.createElement('a');
              link.href = url;
              link.setAttribute('download', 'employeeTimeSheet.xlsx');
              document.body.appendChild(link);
              link.click();
              this.loading = false;
            }).catch(err => {
              console.log(err);
              this.loading = false;
            });
  },

  getExcelData(reportData){
       this.excell_data = [];
        reportData.map((data) => {
            this.excell_data.push({
            "DATE" : this.getDate(data.created_at),
            "DAY" : this.getDay(data.created_at),
            "ACTUAL TIME IN" : data.actual_time_in+' '+data.in_meridiem,
            "ACTUAL TIME OUT" : data.actual_time_out+' '+data.out_meridiem,
            "RECORD TIME IN" : data.record_time_in+' '+data.in_meridiem,
            "RECORD TIME OUT" : data.record_time_out+' '+data.out_meridiem,
            "STATUS" : data.status,
            "LUNCH BREAK" : data.lunch_break,
             "TOTAL HRS" : data.total_hours
          })
        })
      
    },
  
}
}
</script>

<style scoped>

.el-form-item__label{
  font-size: 20px !important;
}
.date_field{
  width: 145px;
}
.label_field{
  font-size: 11px;
  text-align: left;
  color: #000000;
  font-weight: 600;
  margin-bottom: 5px;
}
.field_input{
  -webkit-appearance: none;
  background-color: #F8F8F8;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #d4ccdc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  font-size: 11px;
  height: 26px !important;
  line-height: 30px;
  outline: 0;
  padding: 0 8px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;   
  -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
  box-shadow: 0 0 1px 1px inset #f2f1f9;
 
}
.el-form .el-input__inner{
  font-size: 10px !important;
  display: flex !important;
}
.el-date-editor .el-range-separator{
width: 0% !important;
}

#product th{
  font-size: 11px !important;
  padding-left: 10px;
}
#product td{
  font-size: 11px !important;
  padding-left: 10px;
  height: 22px;
  overflow: auto;
}
#product tr:first-child {
  background-color:#EDEDED;
  border:1px solid #EDEDED;
}
.product_table_hd{
   background-color: #EDEDED !important;
   border:1px solid #EDEDED;
}

#product tr:nth-child(odd) {background-color: #EFF8FC;}
.el-input__suffix{
  margin-top: 5px !important;
}
.el-select .el-input .el-select__caret{
  margin-top: 7px !important;
}

.select_field {
 -webkit-appearance: none;
  background-color: #F8F8F8;
  background-image: none;
  border-radius: 3px;
  border: 1px solid #d4ccdc;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  font-size: 11px;
  height: 26px !important;
  line-height: 30px;
  outline: 0;
  padding: 0 8px;
  -webkit-transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  transition: border-color .2s cubic-bezier(.645,.045,.355,1);
  width: 100%;   
  -webkit-box-shadow: 0 0 1px 1px inset #f2f1f9;
  box-shadow: 0 0 1px 1px inset #f2f1f9;
}
.el-form .el-form-item .el-form-item__label{
font-size: 11px !important;
}
.el-select{
widows: 175px !important;
}
.search_button{
padding: 6px;
  color: white;
  margin-top: 23px;
  padding-left: 10px;
  padding-right: 10px;
}
</style>

